import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { BASE_URL } from "./Api/endpoints";
import "./App.css";
import { loadVendorShop } from "./Redux/Actions/shopActions";
import { clearError, loadVendor } from "./Redux/Actions/vendorActions";
import Routings from "./Routes/Routes";
function App() {
  const dispatch = useDispatch();

  const { error, vendor } = useSelector((state) => state.vendor);

  useEffect(() => {
    dispatch(loadVendor());

    if (error) {
      dispatch(clearError());
    }
  }, []);

  useEffect(() => {
    dispatch(loadVendorShop());

    if (error) {
      dispatch(clearError());
    }
  }, [vendor]);



  // useEffect(async() => {
  //   if (!('serviceWorker' in navigator)) {
  //     alert('Service workers are not supported by this browser');
  //     return;
  //   }

  //   const subscribeUser = async () => {
  //     try {
  //       let sw = await navigator.serviceWorker.ready;
  //       let push = await sw.pushManager.subscribe({
  //         userVisibleOnly: true,
  //         applicationServerKey: 'todo',
  //       });
  //       console.log(JSON.stringify(push));
  //     } catch (error) {
  //       alert(error);
  //     }
  //   };
  //   subscribeUser();
  // }, []);

  return (
    <>
      <Routings />
    </>
  );
}

export default App;
