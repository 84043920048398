import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearError, RegisterVendor } from "../../Redux/Actions/vendorActions";
import "./styles.css";
import toast,{ Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../ButtonLoader/ButtonLoader";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.vendor);
  const [isVerifing, setIsVerifing] = useState(false);

  const [vendor, setvendor] = useState({
    vendorName: "",
    email: "",
    phoneNo: "",
    password: "",
    accountNumber: "",
    ifscCode: "",
    bankName: "",
    accountHolderName: "",
  });

  const HandleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setvendor((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const HandleVendorRegistration = async (e) => {
    e.preventDefault();

    try {
      await dispatch(RegisterVendor(vendor));
      setIsVerifing(true);
      alert("Mail sended Successfully");
      // navigate("/vendor/shopRegistration");
      // toast.success(
      //   "Registered Successfully Redirecting to shop Registration",
      //   {
      //     duration: 1000,
      //     
      //   }
      // );
      // setTimeout(() => {
      //   navigate("/vendor/shopRegistration");
      // }, 1000);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message, {
          duration: 1000,
        });
        setTimeout(() => {
          clearError();
        });
      }else {
        toast.error('Internal Server Error', {
        duration: 1000,
        });
      }
    }
  };

  return (
    <div className="container">
      <h2 className="heading">Vendor Registration</h2>
      <form className="vendor-form" onSubmit={HandleVendorRegistration}>
        <label htmlFor="vendorName">Vendor Name</label>
        <input
          type="text"
          id="vendorName"
          name="vendorName"
          value={vendor.vendorName}
          onChange={HandleChange}
          required
        />

        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={vendor.email}
          required
          onChange={HandleChange}
        />

        <label htmlFor="phoneNo">Phone Number</label>
        <input
          type="tel"
          id="phoneNo"
          name="phoneNo"
          required
          value={vendor.phoneNo}
          onChange={HandleChange}
        />

        <label htmlFor="accountNumber">Your Account no</label>
        <input
          type="Number"
          id="accountNumber"
          name="accountNumber"
          required
          value={vendor.accountNumber}
          onChange={HandleChange}
        />

        <label htmlFor="bankName">Your Bank name</label>
        <input
          type="text"
          id="bankName"
          name="bankName"
          required
          value={vendor.bankName}
          onChange={HandleChange}
        />

        <label htmlFor="ifscCode">Your Bank IFSC Code</label>
        <input
          type="text"
          id="ifscCode"
          name="ifscCode"
          required
          value={vendor.ifscCode}
          onChange={HandleChange}
        />

        <label htmlFor="accountHolderName">Enter your account name</label>
        <input
          type="text"
          id="accountHolderName"
          name="accountHolderName"
          required
          value={vendor.accountHolderName}
          onChange={HandleChange}
        />

        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          name="password"
          value={vendor.password}
          required
          onChange={HandleChange}
        />

        <button type="submit" disabled={isVerifing}>
          {loading ? (
            <ButtonLoader />
          ) : isVerifing ? (
            "A verification link has been sent to your email. Please verify your email to continue. Check Spam folders also"
          ) : (
            "Register"
          )}
        </button>
      </form>
      <Toaster/>
    </div>
  );
};

export default Register;
