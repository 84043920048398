import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.css";
import Button from "@mui/material/Button";
import { MdRestaurantMenu } from "react-icons/md";
import { MdOutlineCallReceived } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { alpha, styled } from "@mui/material/styles";
import { pink, red } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { logoutVendor } from "../../Redux/Actions/vendorActions";
import toast, {Toaster } from "react-hot-toast";
import ButtonLoader from "../ButtonLoader/ButtonLoader";
import axios from "axios";
import { BASE_URL } from "../../Api/endpoints";

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: red[800],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

const label = { inputProps: { "aria-label": "Color switch demo" } };

function Navbar() {
  const navigate = useNavigate();
  const [active, setActive] = React.useState("");
  const [clicked, setClicked] = React.useState(false);
  const dispatch = useDispatch();
  const { vendor, loading, error } = useSelector((state) => state.vendor);
  const { shop, laoding: shopLoading } = useSelector((state) => state.shop);
  const [checked, setChecked] = React.useState(
    shop.status === "open" ? true : false
  );

  useEffect(() => {
    if (shop.status === "closed") {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }, []);

  const navLinksClickHandler = (e, target) => {
    e.preventDefault();

    const targetedElement = document.querySelector(`.${target}`);
    if (targetedElement.classList.contains("active")) {
      return;
    }
    const links = document.querySelectorAll(".link--content");
    links.forEach((link) => {
      if (link !== targetedElement) {
        link.classList.remove("active");
        link.classList.add("non-active");
      } else {
        link.classList.add("active");
        link.classList.remove("non-active");
      }
    });
    navigate(`/${target}`);
  };

  const handleOpeningClosing = async (e) => {
    e.preventDefault();
    console.log(e.target.checked);
    console.log(!e.target.checked);

    try {
      const { data } = await axios.put(
        `${BASE_URL}/shop/changeStatus/${shop._id}`
      );
      setChecked(!e.target.checked);

      if (!checked) {
        toast.success("Your Shop is Opened now 🎉", {
          duration: 1000,
        });
      } else {
        toast.success("Your Shop is closed now", {
         duration: 1000,
        });
      }
    } catch (err) {

      if (err.response) {
        toast.error(err.response.data.message, {
        duration: 1000,
        });
      } else {
        toast.error("Internal Server Error", {
          duration: 1000,
        });
      }
    }
  };

  const LogoutClickHandler = async (e) => {
    e.preventDefault();

    await dispatch(logoutVendor());

    if (error) {
      toast.error(error, {
        duration: 1000,
      });
    } else {
      navigate(`/?keyword=logout`);
    }
  };
  return (
    <>
      {!(loading || shopLoading) && (
        <nav>
          {!shop ? (
            <h1>Internal Server Error</h1>
          ) : (
            <>
              <div className="nav--wrapper top-nav">
                <div className="logo">Cu Food</div>
                <div className="vendor--name">{shop.name}</div>
                <div className="logout--btn">
                  <div className="close--shop--switch">
                    <p>Currently Open</p>
                    <PinkSwitch
                      {...label}
                      checked={checked}
                      onChange={handleOpeningClosing}
                    />
                  </div>
                  <Button variant="contained" onClick={LogoutClickHandler}>
                    Logout
                  </Button>
                </div>
              </div>
              <div className="nav--links">
                <Link
                  to={"/Menu"}
                  className="link--content MenuItem"
                  non-active
                  onClick={(e) => navLinksClickHandler(e, "MenuItem")}
                >
                  <MdRestaurantMenu /> Menu
                </Link>
                <Link
                  className="link--content Orders"
                  non-active
                  onClick={(e) => navLinksClickHandler(e, "Orders")}
                >
                  <MdOutlineCallReceived /> Orders
                </Link>

                <Link
                  className="link--content Profile"
                  non-active
                  onClick={(e) => navLinksClickHandler(e, "Profile")}
                >
                  <CgProfile /> Profile
                </Link>
              </div>
            </>
          )}
          <Toaster />
        </nav>
      )}
    </>
  );
}

export default Navbar;