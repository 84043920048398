import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  vendor: {},
  loading: false,
  error: null,
  isAuthenticated: false,
  shopRegisteringStart: false,
};

export const VendorLoginReducer = createReducer(initialState, {
  VENDOR_LOGIN_REQUEST: (state, action) => {
    state.loading = true;
    state.isAuthenticated = false;
  },
  VENDOR_LOGIN_SUCCESS: (state, action) => {
    state.loading = false;
    state.vendor = action.payload;
    state.isAuthenticated = true;
  },
  VENDOR_LOGIN_FAIL: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
  },
  VENDOR_REGISTER_REQUEST: (state, action) => {
    state.loading = true;
    state.isAuthenticated = false;
  },
  VENDOR_REGISTER_SUCCESS: (state, action) => {
    state.loading = false;
    state.vendor = action.payload;
    state.isAuthenticated = true;
    state.shopRegisteringStart = true;
  },
  VENDOR_REGISTER_FAIL: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAuthenticated = false;
  },
  VENDOR_LOGOUT_REQUEST: (state, action) => {
    state.loading = true;
  },
  VENDOR_LOGOUT_SUCCESS: (state, action) => {
    state.loading = false;
    state.vendor = {};
    state.isAuthenticated = false;
  },
  VENDOR_LOGOUT_FAIL: (state, action) => {
    state.loading = false;
    state.error = action.payload;
    state.isAuthenticated = true;
  },
  VENDOR_SHOP_REGISTER: (state, action) => {
     state.shopRegisteringStart = false;
  },
  CLEAR_ERROR: (state, action) => {
    state.error = null;
    state.loading = false;
  },
  VENDOR_LOAD_REQUEST: (state, action) => {
    state.loading = true;
    state.isAuthenticated = false;
  },
  VENDOR_LOAD_SUCCESS: (state, action) => {
    state.loading = false;
    state.vendor = action.payload;
    state.isAuthenticated = true;
  },
  VENDOR_LOAD_FAIL: (state, action) => {
    state.loading = false;
    state.error = null;
    state.isAuthenticated = false;
  },
});
