import { Dialog } from "@mui/material";
import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import './styles.css'
const PopUp = (props) => {
  const { title, children, openPopup, setOpenPopUp } = props;
  return (
    <Dialog open={openPopup}>
      {/* <DialogTitle>
        <div onClick={() => setOpenPopUp(false)}>X</div>
      </DialogTitle> */}
      <DialogContent style={{ padding: "0 25px" }}>{children}</DialogContent>
    </Dialog>
  );
};

export default PopUp;