import axios from "axios";
import { BASE_URL } from "../../Api/endpoints";
axios.defaults.withCredentials = true;

export const loginVendor = (user) => async (dispatch) => {
  dispatch({ type: "VENDOR_LOGIN_REQUEST" });

  try {
    const { data } = await axios.post(`${BASE_URL}/user/login`, user);

    dispatch({ type: "VENDOR_LOGIN_SUCCESS", payload: data.user });
  } catch (error) {
    if (error.response) {
      error = error.response.data.message;
    }else {
      error = error.message;
    }
    dispatch({ type: "VENDOR_LOGIN_FAIL", payload: error });
  }
};

export const RegisterVendor = (user) => async (dispatch) => {
  dispatch({ type: "VENDOR_REGISTER_REQUEST" });
  
  try {
    const { data } = await axios.post(`${BASE_URL}/user/new?role=vendor`, user);

    dispatch({ type: "VENDOR_REGISTER_SUCCESS", payload: data.user });
  } catch (error) {
    if (error.response) {
      error = error.response.data.message;
    }else {
      error = error.message;
    }
    dispatch({ type: "VENDOR_REGISTER_FAIL", payload: error  });
  }
};

export const loadVendor = () => async (dispatch) => {
  dispatch({ type: "VENDOR_LOAD_REQUEST" });
  try {
    const { data } = await axios.get(`${BASE_URL}/user/logedInUser`);

    await dispatch({ type: "VENDOR_LOAD_SUCCESS", payload: data.user });

  } catch (error) {
    if (error.response) {
      error = error.response.data.message;
    }else {
      error = error.message;
    }
    dispatch({ type: "VENDOR_LOAD_FAIL", payload: error });
  }
};

export const logoutVendor = () => async (dispatch) => {
  dispatch({ type: "VENDOR_LOGOUT_REQUEST" });
  try {
    await axios.get(`${BASE_URL}/user/logout`);

    dispatch({ type: "VENDOR_LOGOUT_SUCCESS" });
  } catch (error) {
    if (error.response) {
      error = error.response.data.message;
    }else {
      error = error.message;
    }
    dispatch({ type: "VENDOR_LOGOUT_FAIL", payload: error });
  }
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: "CLEAR_ERROR" });
};
