import React, { useEffect, useState } from "react";
import AddMenu from "./AddMenu";
import axios from "axios";
import PopUp from "./PopUp";
import UpdateMenu from "./UpdateMenu";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../Api/endpoints";
import toast,{Toaster} from "react-hot-toast"; 
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import "./styles.css";
import SiteLoader from "../SiteLoader/SiteLoader";
import MenuLoader from "../SiteLoader/MenuLoader";

const theme = createTheme({
  palette: {
    primary: {
      main: "#DC143C", // Crimson color
    },
  },
});

const Menu = () => {
  const { shop } = useSelector((state) => state.shop);
  const [openPopup, setOpenPopUp] = useState(false);
  const [itemUpdate, setItemUpdate] = useState(null);
  const [show, setShow] = useState(true);
  const [menu, setMenu] = useState(null);
  const [staringMenu, setStaringMenu] = useState(null); // for search
  const [id, setId] = useState(null);
  const [search, setSearch] = useState("");
  const [load, setLoad] = useState(1);

  const [open, setOpen] = useState(false);
  const [menuName, setMenuName] = useState("");

  const query = new URLSearchParams(window.location.search);
  const keyword = query.get("keyword");

  const handleClickOpen = (id, name) => {
    setOpen(true);
    setId(id);
    setMenuName(name);
  };

  const handleClose = () => {
    setOpen(false);
    setId(null);
    setMenuName("");
  };

  useEffect(() => {
    document.title = "Menu | Vendor";
  }, []);

  const handleYes = (id) => {
    try {
      axios.delete(`${BASE_URL}/food/deleteFromMenu/` + id + "/" + shop._id);
      toast.success(`${menuName} Deleted Successfully from your menu`, {
        duration: 1000,
      });
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message, {
          duration: 1000,
        });
      } else {
        toast.error("Internal Server Problem", {
         duration: 1000,
        });
      }
    }
    setOpen(false);
    setId(null);
    setMenuName("");
  };

  const fetchData = async () => {
    axios.get(`${BASE_URL}/shop/getMenu/` + shop._id).then((res) => {
      if (res.data.Menu) {
        console.log(res.data.Menu);
        setStaringMenu(res.data.Menu);
        setMenu(res.data.Menu);
        setLoad(0);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [show]);

  useEffect(() => {}, [show]);

  const handleUpdate = (item) => {
    setItemUpdate(item);
    setOpenPopUp(true);
    return;
  };

  const handleSearch = async () => {
    setLoad(true);
    try {
      const { data } = await axios.get(
        `${BASE_URL}/shop/getMenu/${shop._id}?keyword=${search}`
      );

      console.log(data);
      if (search.length > 0) {
        if (data["Menu"].length !== 0) {
          setMenu(data.Menu);
        } else {
          setMenu([]);
        }
      } else {
        fetchData();
      }
      setLoad(false);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message, {
          duration: 1000,
        });
      } else {
        toast.error("Internal Server Problem", {
          duration: 1000,
        });
      }
      setLoad(0);
    }
  };

  const HandleStocks = async (item, option) => {
    console.log(item, option);
    try {
      await axios.put(`${BASE_URL}/food/updateStocks/${item}?option=${option}`);

      if (option === "StockOut") {
        toast.success("Item is now out of stock", {
          duration: 1000,
        });
      } else if (option === "StockIn") {
        toast.success("Item is in now stock", {
         duration: 1000,
        });
        fetchData();
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message, {
          duration: 1000,
        });
      } else {
        toast.error("Internal Server Problem", {
          duration: 1000,
        });
      }
    }
  };

  useEffect(() => {
    if (search.length > 0) {
      handleSearch();
    } else {
      fetchData();
    }
  }, [search]);

  return (
    <div className="ShopPortall">
      <ThemeProvider theme={theme}>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">{"Confirm "}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete {menuName ? menuName : "this"}{" "}
                from your menu?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                No
              </Button>
              <Button onClick={() => handleYes(id)} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </ThemeProvider>
      {shop && (
        <div className="ShopDataa">
          <h2>{shop.name}</h2>
          <div className="shopImgg">
            <img src={shop.image.path} alt="" />
          </div>

          <p>
            <span style={{ fontWeight: 500 }}>Description: </span>
            {shop.description}
          </p>
          <div className="MenuItemss">
            <p></p>
          </div>
        </div>
      )}
      {show && (
        <div className="Showw">
          <h6>Menu</h6>
          <div className="search-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
              className="searchIconn"
              width={20}
              height={20}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
            <input
              type="text"
              className="searchMenu"
              placeholder={`Search in Menu`}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            ></input>
          </div>

          {load ? (
            <MenuLoader style= {{color : "#D80032"}}/>
          ) : (
            <div className="ShowMenuu">
              {menu &&
                menu.map((item) => {
                  console.log(item);
                  return (
                    <div key={item._id}>
                      <div className="ItemDataa">
                        <div className="Itemm">
                          <div className="imagee">
                            <img src={item.image.path} alt="" />
                          </div>
                          <div className="ItemDetailss">
                            <div className="Item_topp">
                              <div className="Item_leftt">
                                <h3>{item.name}</h3>
                                <p>{item.category}</p>
                              </div>
                              <div className="Item_rightt">
                                <Button
                                  onClick={() =>
                                    handleClickOpen(item._id, item.name)
                                  }
                                  variant="contained"
                                  color="error"
                                >
                                  Delete Item
                                </Button>
                                <Button
                                  onClick={() =>
                                    HandleStocks(
                                      item._id,
                                      item.stockAvailability
                                        ? "StockOut"
                                        : "StockIn"
                                    )
                                  }
                                  variant="contained"
                                  color={
                                    item.stockAvailability ? `error` : `primary`
                                  }
                                >
                                  {item.stockAvailability
                                    ? "Stock Out"
                                    : "Stock In"}
                                </Button>
                              </div>
                            </div>
                            <div className="Item_topp">
                              <div className="Item_leftt">
                                <h3>Half- {item.price_half}</h3>
                                {/* <p>Full- {item.price_full}</p> */}
                              </div>
                              <div className="Item_rightt">
                                <Button
                                  onClick={() => handleUpdate(item)}
                                  variant="contained"
                                >
                                  Edit Item
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="innerr">
                          <p style={{ margin: 20 + "px " + 0 + "px" }}>
                            <b>Description:</b> {item.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          <div className="ChangeShoww">
            <Button
              onClick={() => {
                setShow(false);
              }}
              variant="contained"
            >
              ADD Item
            </Button>
          </div>
        </div>
      )}
      {!show && (
        <div>
          <AddMenu id={shop._id} setShow={setShow} />
        </div>
      )}
      <PopUp title="Title" openPopup={openPopup} setOpenPopUp={setOpenPopUp}>
        <UpdateMenu id={shop._id} setShow={setOpenPopUp} item={itemUpdate} />
      </PopUp>
      <Toaster />
    </div>
  );
};

export default Menu;
