import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast,{ Toaster}  from "react-hot-toast";
import sound from "../../Assets/NotificationAudio/orders.mp3";
import {
  clearError,
  getAllActiveOrders,
  getAllDeliveredOrders,
  getNewOrders,
  openSearchedOrder,
} from "../../Redux/Actions/orderActions";

import { messaging } from "../../firebase";
import { getToken } from "firebase/messaging";

import OrderDetails from "./OrderDetails";
import socketIO from "socket.io-client";
import { BiSearch } from "react-icons/bi";
import axios from "axios";
import { BASE_URL } from "../../Api/endpoints";

const ENDPOINT = "https://api.cufoodz.com";
let socket;
socket = socketIO.connect(ENDPOINT, { transports: ["websocket"] });

const Orders = () => {
  const { vendor } = useSelector((state) => state.vendor);
  const [but, setbutton] = useState("new");
  const { orders, loading, error } = useSelector((state) => state.order);
  const [otp, setOtp] = useState(null);
  const [otpOrder, setOtpOrder] = useState(null);
  const { shop } = useSelector((state) => state.shop);

  const { otpPop } = useSelector((state) => state.SearchOtpOrder);

  const { activeOrders, error: activeOrderError } = useSelector(
    (state) => state.activeOrder
  );

  const { deliveredOrders, error: DeliveredOrderError } = useSelector(
    (state) => state.deliveredOrder
  );

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Orders | Vendor";
  }, []);

  // Secket Io
  useEffect(() => {
    socket.on("newOrder", (data) => {
      if (data.vendorId === vendor._id) {
        dispatch(getNewOrders());
        setbutton("new");

        toast.success("New Order Received", {
          duration: 1000,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (error || activeOrderError || DeliveredOrderError) {
      toast.error(error, {
        duration: 1000,
      });
      dispatch(clearError());
    }
    dispatch(getNewOrders());
    dispatch(getAllActiveOrders());
    dispatch(getAllDeliveredOrders());
  }, []);

  const searchOtp = async (e) => {
    e.preventDefault();
    console.log("searching");
    console.log(otp);
    try {
      if (otp) {
        const { data } = await axios.get(
          `${BASE_URL}/order/getOrderByOtp/${otp}`
        );
        const order = data.order;
        setOtpOrder(order);
        setOtp(null);
        dispatch(openSearchedOrder());
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message, {
          duration: 1000,
        });
      } else {
        toast.error("Internal Server Error", {
          duration: 1000,
        });
      }
    }
    document.getElementById("otp").value = null;
  };

  const clicked = (e) => {
    e.preventDefault();
    setbutton(e.target.name);
  };

  async function requestPermission() {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      // Generate the token
      const token = await getToken(messaging, {
        vapidKey:
          "BN2qxgmXXEHiwX6tiCTXJfvOfrerNaTMC0xUETAMQvd-R1QxSDmTwCSg-xSquAqu9WBXZIVUflYFIz8XmJc7mJ0",
      });
      console.log("token", token);
      axios.get(`${BASE_URL}/notification/registerToken/${token}`);
    } else if (permission === "denied") {
      // axios.get(`${BASE_URL}/notification/UnregisterToken`);
    }
  }

  useEffect(() => {
    requestPermission();

    // Add an event listener to listen for changes in notification permission
    document.addEventListener("notification", async (event) => {
      if (event.target.permission === "granted") {
        // User granted permission
        // Handle the notification setup here
        const token = await getToken(messaging, {
          vapidKey:
            "BN2qxgmXXEHiwX6tiCTXJfvOfrerNaTMC0xUETAMQvd-R1QxSDmTwCSg-xSquAqu9WBXZIVUflYFIz8XmJc7mJ0",
        });
        console.log("token listen", token);
        axios.get(`${BASE_URL}/notification/registerToken/${token}`);
      } else if (event.target.permission === "denied") {
        // User denied permission
        // Handle the denied case if needed
      }
    });
  }, []);

  return (
    <div>
      <div className="Orders">
        <div className="Otp--search">
          <input
            type="number"
            name="otp"
            id="otp"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                searchOtp(e);
              }
            }}
            placeholder="Enter OTP for searching order"
          />
          <div
            className="search--logo--container"
            style={{ cursor: "pointer" }}
            onClick={searchOtp}
          >
            <BiSearch className="search--icon" />
          </div>
        </div>
        <div className="orderOptions">
          <button
            name="Active"
            className={but == "Active" ? "activeButton" : ""}
            onClick={clicked}
          >
            Active
          </button>
          <button
            name="new"
            className={`${but === "new" && "activeButton"}`}
            onClick={clicked}
          >
            New
          </button>
          <button
            name="Delivered"
            className={but == "Delivered" ? "activeButton " : " "}
            onClick={clicked}
          >
            Delivered
          </button>
        </div>
        {but === "new" && otpPop != true && (
          <div className="ShowOrders">
            {orders.map((order) => (
              <OrderDetails
                key={order._id}
                id={order._id}
                Otp={order.Otp ? order.Otp : null}
                status={order.paymentInfo.status}
                OrderItems={order.OrderItems}
                orderStatus={order.orderStatus}
                PlacedOn={order.createdAt.toString().substr(0, 10)}
                totalPrice={order.totalPrice}
                Address={order.deliveryAddress ? order.deliveryAddress : null}
                name={shop.name}
                deliveryType={order.deliveryType}
                phoneNumber={order.phoneNumber}
                newOrder={true}
                userName={order.userName}
              />
            ))}
          </div>
        )}
        {but === "Active" && otpPop != true && (
          <div className="ShowOrders">
            {activeOrders.map((order) => (
              <OrderDetails
                key={order._id}
                id={order._id}
                OrderItems={order.OrderItems}
                Otp={order.Otp ? order.Otp : null}
                status={order.paymentInfo.status}
                orderStatus={order.orderStatus}
                PlacedOn={order.createdAt.toString().substr(0, 10)}
                totalPrice={order.totalPrice}
                Address={order.deliveryAddress ? order.deliveryAddress : null}
                name={shop.name}
                Options={true}
                closeButton={false}
                phoneNumber={order.phoneNumber}
                deliveryType={order.deliveryType}
                userName={order.userName}
              />
            ))}
          </div>
        )}
        {but === "Delivered" && otpPop != true && (
          <div className="ShowOrders">
            {deliveredOrders.map((order) => (
              <OrderDetails
                key={order._id}
                id={order._id}
                Otp={order.Otp ? order.Otp : null}
                OrderItems={order.OrderItems}
                status={order.paymentInfo.status}
                orderStatus={order.orderStatus}
                PlacedOn={order.createdAt.toString().substr(0, 10)}
                totalPrice={order.totalPrice}
                Address={order.deliveryAddress ? order.deliveryAddress : null}
                name={shop.name}
                phoneNumber={order.phoneNumber}
                deliveryType={order.deliveryType}
                userName={order.userName}
              />
            ))}
          </div>
        )}
        {otpOrder && otpPop && (
          <OrderDetails
            key={otpOrder._id}
            id={otpOrder._id}
            Otp={otpOrder.Otp ? otpOrder.Otp : null}
            status={otpOrder.paymentInfo.status}
            orderStatus={otpOrder.orderStatus}
            PlacedOn={otpOrder.createdAt.toString().substr(0, 10)}
            totalPrice={otpOrder.totalPrice}
            Address={otpOrder.deliveryAddress ? otpOrder.deliveryAddress : null}
            name={shop.name}
            Options={true}
            
            closeButton={true}
          />
        )}
      </div>
   
      <Toaster />
    </div>
  );
};

export default Orders;
