import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Api/endpoints";
import { loadVendor } from "../../Redux/Actions/vendorActions";
import ShopRegistrationForm from "./ShopRegistration";

const VerifyEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;

    const verifyUser = async () => {
      try {
        await axios.put(`${BASE_URL}/user/verify/${token}`);
        if (isMounted) {
          dispatch(loadVendor());
          navigate("/vendor/shopRegistration");
        }
      } catch (err) {
        console.log("Error:", err);
        if (isMounted) {
          if (err.response) {
            navigate(`/?verifyError=${err.response.data.message}`);
          } else {
            navigate(`/?verifyError=Something went wrong`);
          }
        }
      }
    };

    verifyUser();

    return () => {
      // Cleanup function to handle unmounting
      isMounted = false;
    };
  }, []);

  return <div><ShopRegistrationForm/></div>;
};

export default VerifyEmail;
