import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "../../Api/endpoints";
import {
  clearError,
  loginVendor,
  logoutVendor,
} from "../../Redux/Actions/vendorActions";
import toast, { Toaster } from "react-hot-toast";
import { loadVendorShop } from "../../Redux/Actions/shopActions";
import ButtonLoader from "../ButtonLoader/ButtonLoader";
import ReCAPTCHA from "react-google-recaptcha";
const Login = () => {
  const captchaRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    error,
    loading,
    isAuthenticated,
    vendor: logedInVendor,
  } = useSelector((state) => state.vendor);

  const { shop, loading: shopLoading } = useSelector((state) => state.shop);
  const query = new URLSearchParams(window.location.search);
  const keyword = query.get("keyword");
  const verifyError = query.get("verifyError");
  const handleCaptchaChange = (value) => {
    setvendor((prev) => {
      return { ...prev, ["captchaValue"]: value };
    });
  };
  useEffect(() => {
    if (verifyError) {
      toast.error(verifyError, {
        duration: 1000,
      });

      setTimeout(() => {
        navigate("/");
      }, 1500);
    }
  }, []);

  useEffect(() => {
    if (keyword === "logout") {
      toast.success("Logged Out Successfully", {
        duration: 1000,
      });
    }
    setTimeout(() => {
      navigate("/");
    });
  }, []);
  const [vendor, setvendor] = useState({
    email: "",
    password: "",
    captchaValue: "",
  });

  const googleLoginHandler = () => {
    try {
      window.open(`${BASE_URL}/user/googleAuth`, "_self");
    } catch (err) {
      navigate("/");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setvendor((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const loginSubmitHandler = (e) => {
    dispatch(clearError());
    e.preventDefault();
    if (vendor.password.length < 9) {
      toast.error("Password less than 9 characters");
      return;
    }
    if (!vendor.captchaValue) {
      toast.error("Complete Captcha First");
      return;
    }
    captchaRef.current.reset();
    dispatch(loginVendor(vendor));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (error) {
        toast.error(error, {
          duration: 1000,
        });
        navigate("/");
        return;
      }

      if (isAuthenticated === true) {
        try {
          await dispatch(loadVendorShop());
          // Now that loadVendorShop has completed, check loading and shopLoading.
          if (loading === false && shopLoading === false) {
            // console.log("Is authenticated", isAuthenticated);
            // console.log("Shop", shop);
            // console.log("loading", loading);
            // console.log("shopLoading", shopLoading);
            if (isAuthenticated && Object.keys(shop).length > 0) {
              navigate("/Orders");
            }
            // else {
            //   console.log("You are here");
            //   toast.error("Please Register Your Shop");
            //   navigate("/");
            // }
          }
        } catch (error) {
          if (error.response) {
            toast.error(error.response.data.message, {
              duration: 1000,
            });
          } else {
            toast.error("Something went wrong", {
              duration: 1000,
            });
          }
        }
      }
    };

    fetchData();
  }, [isAuthenticated]);

  // useEffect(() => {
  //   const fetchData =  async () => {
  //     if (error) {
  //       toast.error(error, {
  //         duration: 1000,
  //
  //       });
  //       navigate("/");
  //       return;
  //     }

  //     if (isAuthenticated === true) {
  //       const id = logedInVendor._id;

  //        await dispatch(loadVendorShop());

  //       if (loading === false && shopLoading === false) {
  //         if (isAuthenticated && Object.keys(shop).length > 0) {
  //           navigate("/Orders");
  //         } else {
  //           dispatch(logoutVendor());
  //           alert('you are right')
  //           toast.error("Please Register Your Shop");
  //           navigate("/");
  //         }
  //       }else {
  //         console.log('you are wrong')
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [
  //   error,
  //   dispatch,
  //   loading,
  //   shopLoading,
  //   isAuthenticated,
  //   logedInVendor,
  //   shop,
  // ]);

  return (
    <>
      {!isAuthenticated && (
        <div className="login-container" onSubmit={loginSubmitHandler}>
          <h2>LOGIN</h2>
          <form className="login_form">
            <input
              className="login_input"
              onChange={handleChange}
              type="text"
              value={vendor.email}
              name="email"
              placeholder="Email"
              required
            />
            <input
              className="login_input"
              onChange={handleChange}
              type="password"
              value={vendor.password}
              name="password"
              placeholder="Password"
              required
            />
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={handleCaptchaChange}
              ref={captchaRef}
            />
            {error && <p className="error">Incorrect email or password</p>}
            <button className="login_button" type="submit">
              {loading ? <ButtonLoader /> : "Log In"}
            </button>
          </form>

          {/* <div className="login_with_google">
            <p className="seprater--text"> Or Login With </p>

            <button
              type="button"
              className="login-with-google-btn"
              onClick={googleLoginHandler}
            >
              Sign in with Google
            </button>
          </div>
          <div className="notAmember">
            <p>Not A Member? </p>
            <span>
              <a href="/register">Register</a>
            </span>
          </div> */}
        </div>
      )}
      <Toaster />
    </>
  );
};

export default Login;
