const { createReducer } = require("@reduxjs/toolkit");

const initialState = {
  orders: [],
  loading: false,
  error: null,
};

const initialStateActive = {
  activeOrders: [],
  loading: false,
  error: null,
};

const initialStateDelivered = {
  deliveredOrders: [],
  loading: false,
  error: null,
};

const otpState = {
  loading: false,
  otpPop: false,
}

export const orderReducer = createReducer(initialState, {
  GET_NEW_ORDERS_REQUEST: (state, action) => {
    state.loading = true;
  },
  GET_NEW_ORDERS_SUCCESS: (state, action) => {
    state.orders = action.payload;
    state.loading = false;
  },
  GET_NEW_ORDERS_FAIL: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  CLEAR_ERROR: (state, action) => {
    state.error = null;
    state.loading = false;
  },
});

export const orderReducerActive = createReducer(initialStateActive, {
  GET_ALL_ACTIVE_ORDERS_REQUEST: (state, action) => {
    state.loading = true;
  },
  GET_ALL_ACTIVE_ORDERS_SUCCESS: (state, action) => {
    state.activeOrders = action.payload;
    state.loading = false;
  },
  GET_ALL_ACTIVE_ORDERS_FAIL: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  CLEAR_ERROR: (state, action) => {
    state.error = null;
    state.loading = false;
  },
});

export const orderReducerDelivered = createReducer(initialStateDelivered, {
  GET_ALL_DELIVERED_ORDERS_REQUEST: (state, action) => {
    state.loading = true;
  },
  GET_ALL_DELIVERED_ORDERS_SUCCESS: (state, action) => {
    state.deliveredOrders = action.payload;
    state.loading = false;
  },
  GET_ALL_DELIVERED_ORDERS_FAIL: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  CLEAR_ERROR: (state, action) => {
    state.error = null;
    state.loading = false;
  },
});

export const otpReducer = createReducer(otpState, {
  OTP_ORDER_POP: (state, action) => {
      state.otpPop = true;
  },
  OTP_ORDER_CLOSE: (state, action) => {
      state.otpPop = false;
  },
})

export const orderResponse = createReducer(initialState, {
  ORDER_RESPONSE: (state, action) => {
    state.orders = action.payload;
  },
  ORDER_RESPONSE_FAIL: (state, action) => {
    state.error = action.payload;
  }
});

// export const notRespondedOrderReducer = createReducer(initialState, {
//   ORDER_NOT_RESPONDED: (state, action) => {
//     state.orders = action.payload;
//   },
// });

// export const RejectedOrderReducer = createReducer(initialState, {
//   ORDER_NOT_RESPONDED: (state, action) => {
//     state.orders = action.payload;
//     loading = false;
//   }
// });

// export const DeliveredOrderReducer = createReducer(initialState, {
//   ORDER_NOT_RESPONDED: (state, action) => {
//     state.orders = action.payload;
//     loading = false;
//   }
// });

// export const PreparingOrderReducer = createReducer(initialState, {
//   ORDER_NOT_RESPONDED: (state, action) => {
//     state.orders = action.payload;
//     loading = false;
//   }
// });