import React from "react";
import "./styles.css";

const SiteLoader = ({ variant }) => {
  return (
    <>
      {variant === "small" ? (
      <div className="loader--container-small">
          <span class="loader"></span>
        </div>
      ) : (
        <div className="loader--container">
          <span class="loader"></span>
        </div>
      )}
    </>
  );
};

export default SiteLoader;
