import React, { useState } from "react";
import styled from "styled-components";
import { BASE_URL } from "../../Api/endpoints";
import  toast,{ Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { registerShop } from "../../Redux/Actions/shopActions";
import ButtonLoader from "../ButtonLoader/ButtonLoader";
import { useNavigate } from "react-router-dom";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  max-width: 600px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #333;
  margin: 0 auto;
  margin-top: 10vh;

  @media (max-width: 768px) {
    min-height: 100vh;
    max-width: 400px;
    margin: 0 auto;
  }
`;

const Heading = styled.h2`
  color: #800000;
  margin-bottom: 20px;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 200px;
  margin-top: 10px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  margin-bottom: 8px;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FileInput = styled.input`
  margin-bottom: 16px;
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 10px;
  background-color: #8b0000;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
`;

const ShopRegistrationForm = () => {
  const [shopName, setShopName] = useState("");
  const [shopDescription, setShopDescription] = useState("");
  const [openAt, setOpenAt] = useState("");
  const [closeAt, setCloseAt] = useState("");
  const [restaurantImage, setRestaurantImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const { loading } = useSelector((state) => state.shop);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", shopName);
      formData.append("description", shopDescription);
      formData.append("openAt", openAt);
      formData.append("closeAt", closeAt);
      formData.append("image", restaurantImage);

      await dispatch(registerShop(formData));

      toast.success(
        "Shop registration successfully, We'll let you know once it gets approved",
        {
         duration: 1000,
        }
      );

      // setTimeout(() => {
      //   navigate("/orders");
      // }, 1000);
    } catch (error) {
      console.error("Error in shop registering", error);
      toast.error(error, {
     duration: 1000,
      });
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setRestaurantImage(selectedImage);

    // Display image preview
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  };

  return (
    <FormContainer>
      <Heading>Shop Registration Form</Heading>
      <Form onSubmit={handleSubmit}>
        <Label>Shop Name</Label>
        <Input
          type="text"
          value={shopName}
          onChange={(e) => setShopName(e.target.value)}
        />
        <Label>Shop Description</Label>
        <Input
          type="text"
          value={shopDescription}
          onChange={(e) => setShopDescription(e.target.value)}
        />
        <Label>Open At</Label>
        <Input
          type="time"
          value={openAt}
          onChange={(e) => setOpenAt(e.target.value)}
        />
        <Label>Close At</Label>
        <Input
          type="time"
          value={closeAt}
          onChange={(e) => setCloseAt(e.target.value)}
        />
        <Label>Restaurant Image</Label>
        <FileInput type="file" accept="image/*" onChange={handleImageChange} />
        {imagePreview && <ImagePreview src={imagePreview} alt="Preview" />}

        <Button type="submit">{loading ? <ButtonLoader /> : "Register"}</Button>
      </Form>
      <Toaster />
    </FormContainer>
  );
};

export default ShopRegistrationForm;
