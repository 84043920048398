import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { orderReducer, orderReducerActive, orderReducerDelivered, otpReducer } from "./Reducers/orderReducer";
import {shopReducer } from "./Reducers/shopReducer";
import { VendorLoginReducer } from "./Reducers/vendorReducer";

const initialState = {};
const rootReducer = combineReducers({
  vendor: VendorLoginReducer,
  order: orderReducer,
  activeOrder: orderReducerActive,
  deliveredOrder: orderReducerDelivered,
  shop : shopReducer,
  SearchOtpOrder : otpReducer
});

const store = configureStore({
  reducer: rootReducer,
  initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
