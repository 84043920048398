import React, { useState } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../Api/endpoints";
import "./styles.css";
import SiteLoader from "../SiteLoader/SiteLoader";

const AddMenu = ({ id, setShow }) => {
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [category, setCategory] = useState("");
  const [price_half, setHalfPrice] = useState(0);
  const [price_full, setFull] = useState(0);
  const [image, setImage] = useState(null);
  const [path, setPath] = useState("");
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = async (e) => {
    setLoading(true);
    console.log("clicked");
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("DualOptions", checked);
    formData.append("category", category);
    if (checked) {
      formData.append("price_full", price_full);
    }

    formData.append("price_half", price_half);
    formData.append("image", image);
    const res = await axios.post(
      `${BASE_URL}/food/addFoodItem/` + id,
      formData
    );
    console.log(res);

    setImage(null);
    setdescription("");
    setPath("");
    setname("");
    setCategory("");
    setChecked(!checked);
    setShow(true);
    setLoading(false);
  };
  return (
    <div className="Menuu">
      <h2>Menu</h2>
      <div className="AddItemm">
        <div className="itemNamee">
          <label>Item Name</label>
          <input
            type="text"
            onChange={(e) => {
              setname(e.target.value);
            }}
            value={name}
            name="name"
          />
        </div>
        <div className="categoryy">
          <label>Category</label>
          <input
            type="text"
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            value={category}
            name="category"
          />
        </div>
        <div className="itemDescriptionn">
          <label>Description</label>
          <textarea
            name="description"
            onChange={(e) => {
              setdescription(e.target.value);
            }}
            value={description}
            id=""
            cols="24"
            rows="1"
          ></textarea>
        </div>
        <div className="DualOptionn">
          <input
            type="checkbox"
            name="DualOptions"
            onClick={() => {
              setChecked(!checked);
            }}
          />
          <label>DualOption</label>
        </div>
        <div className="itemPrice_halff">
          <label>Price(half)</label>
          <input
            type="number"
            onChange={(e) => {
              setHalfPrice(e.target.value);
            }}
            value={price_half}
            name="price_half"
          />
        </div>
        <div className="itemPrice_fulll">
          <label>Price(full)</label>
          <input
            type="number"
            onChange={(e) => {
              setFull(e.target.value);
            }}
            value={price_full}
            name="price_full"
            disabled={!checked}
          />
        </div>
        <div>
          <label className="imgg">
            + Add Image
            <input
              type="file"
              name="image"
              accept="image/png , image/jpeg, image/webp , image/jpg"
              onChange={(e) => {
                console.log(e.target.files[0]);
                setImage(e.target.files[0]);
                setPath(e.target.value);
              }}
              value={path}
            />
          </label>
          <p style={{ marginBottom: 10 + "px" }}>{path}</p>
        </div>
      </div>
      {loading ? (
        <Button
          disabled
          variant="contained"
          style={{ backgroundColor: "#D80032" }}
        >
          <SiteLoader variant={"small"} />
        </Button>
      ) : (
        <Button
          type="Submit"
          onClick={handleClick}
          variant="contained"
          style={{ backgroundColor: "#D80032" }}
        >
          Create
        </Button>
      )}
      <Button
        style={{ margin: 10 + "px " + 0 }}
        type="Submit"
        onClick={() => {
          setShow(true);
        }}
        variant="contained"
      >
        Cancel
      </Button>
    </div>
  );
};

export default AddMenu;
