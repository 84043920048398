import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../components/Home/Home";
import Menu from "../components/Menu/Menu";
import Navbar from "../components/Navbar/Navbar";
import Orders from "../components/Orders/Orders";
import Profile from "../components/Profile/Profile";
import ProtectedRoutes from "../components/ProtectedRoutes/ProtectedRoutes";
import Login from "../components/VendorAuth/Login";
import Register from "../components/VendorAuth/Register";
import ShopRegistration from "../components/VendorAuth/ShopRegistration";
import VerifyEmail from "../components/VendorAuth/VerifyEmail";

function Routings() {
  const { isAuthenticated, loading, shopRegisteringStart } = useSelector(
    (state) => state.vendor
  );
  const { shop, loading: shopLoading } = useSelector((state) => state.shop);
  return (
    <>
      <Router>
        {isAuthenticated &&
          !shopRegisteringStart &&
          Object.keys(shop).length > 0 && <Navbar />}

        <Routes>
          <Route
            element={
              <ProtectedRoutes
                isAuthenticated={isAuthenticated}
                loading={loading}
                shopLoading={shopLoading}
                shop={shop}
              />
            }
          >
            <Route>
              <Route path="/MenuItem" element={<Menu />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/Profile" element={<Profile />} />
            </Route>
          </Route>
          <Route
            path="/vendor/shopRegistration"
            element={<ShopRegistration />}
          />
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/vendor/verifyEmail" element={<VerifyEmail />} />
        </Routes>
      </Router>
    </>
  );
}

export default Routings;
