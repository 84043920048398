import axios from "axios";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../Api/endpoints";
import "./styles.css";

const Profile = () => {
  const { shop } = useSelector((state) => state.shop);
  const { vendor } = useSelector((state) => state.vendor);

  const withdrawHandler = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/user/vendorWithdrwal`);

      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    document.title = "Profile | Vendor";
  }, []);

  return (
    <div className="profile--container">
      <div className="left--side--shop--details">
        <div className="shop--right--image--container">
          <img src={shop.image.path} alt="Shop" className="shop--image" />
        </div>
        <div className="shop--details--container">
          <h1 className="shop--name Shopheading">{shop.name}</h1>

          <h2 className="owner--name">Owner: {vendor.name}</h2>
          <br />
          <p className="registered--on">
            Registered On: {shop.createdAt.toString().substr(0, 10)}
          </p>
          <br />

          <p className="shop--address">Address:- {shop.description}</p>
          <br />
          <p className="shop--status">Status: {shop.status}</p>
          <br />
          <p className="shop--opening-time">
            Opening Time: {shop.openAt} - Closing Time: {shop.closeAt}
          </p>
        </div>
      </div>
      <div className="right--side--shop--details">
        <div className="total--earnings--status">
          <h2 className="revenue--summary Shopheading">Revenue Summary</h2>
          <p className="order--info">Total Orders Accepted: 0</p>
          <p className="order--info">Total Orders Rejected: 0</p>
          <br />
          <p className="order--info">
            Today's Orders Accepted : {shop.TodayAcceptedOrder.length.toLocaleString()}
          </p>
          <p className="order--info">
            Today's Orders Rejected : {shop.TodayRejectedOrder.length.toLocaleString()}
          </p>
          <br />
          <p className="total--earnings">
            Total Earnings: ₹{shop.TotalEarnings.toLocaleString()}
          </p>
          <p className="todays--earnings">
            Today's Earnings: ₹{shop.TodaysEarnings.toLocaleString()}
          </p>
          <br />
          <p className="current--balance">
            <strong>Current Balance:</strong> ₹{shop.Balance.toLocaleString()}
          </p>
          <div className="withdrawal--btn">
            <button className="withdraw--button" onClick={withdrawHandler}>
              Withdraw
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
