import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getMessaging } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyBj9Bh8R5R6LKfFLSKWi5cBj4a0V1wkFHI",
  authDomain: "cufoodz-notifications.firebaseapp.com",
  projectId: "cufoodz-notifications",
  storageBucket: "cufoodz-notifications.appspot.com",
  messagingSenderId: "403864233251",
  appId: "1:403864233251:web:8521cf781e4da7046a1896",
  measurementId: "G-Z1WRRZ0XX4",
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
const analytics = getAnalytics(app);
