import {createReducer} from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    shop: {},
}

export const shopReducer = createReducer(initialState, {
    SHOP_REGISTER_REQUEST: (state, action) => {
        state.loading = true;
    },
    SHOP_REGISTER_SUCCESS: (state, action) => {
        state.loading = false;
        state.shop = action.payload;
    },
    SHOP_REGISTER_FAIL: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    LOAD_VENDOR_SHOP_REQUEST: (state, action) => {
        state.loading = true;
    },
    LOAD_VENDOR_SHOP_SUCCESS: (state, action) => {
        state.loading = false;
        state.shop = action.payload;
    },
    LOAD_VENDOR_SHOP_FAIL: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    CLEAR_ERROR: (state, action) => {
        state.error = null;
        state.loading = false;
    }
})

