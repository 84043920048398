import axios from "axios";
import { BASE_URL } from "../../Api/endpoints";

export const getNewOrders = () => async (dispatch) => {
  dispatch({ type: "GET_NEW_ORDERS_REQUEST" });

  try {
    const { data } = await axios.get(`${BASE_URL}/order/getNewOrders`);

    dispatch({ type: "GET_NEW_ORDERS_SUCCESS", payload: data.orders });
  } catch (error) {
    if (error.response) {
      error = error.response.data.message;
    } else {
      error = error.message;
    }
    dispatch({ type: "GET_NEW_ORDERS_FAIL", payload: error });
  }
};

export const getAllActiveOrders = () => async (dispatch) => {
  dispatch({ type: "GET_ALL_ACTIVE_ORDERS_REQUEST" });

  try {
    const { data } = await axios.get(`${BASE_URL}/order/getAllActiveOrders`);
    dispatch({ type: "GET_ALL_ACTIVE_ORDERS_SUCCESS", payload: data.orders });
  } catch (error) {
    if (error.response) {
      error = error.response.data.message;
    } else {
      error = error.message;
    }
    dispatch({ type: "GET_ALL_ACTIVE_ORDERS_FAIL", payload: error });
  }
};

export const getAllDeliveredOrders = () => async (dispatch) => {
  dispatch({ type: "GET_ALL_DELIVERED_ORDERS_REQUEST" });

  try {
    const { data } = await axios.get(
      `${BASE_URL}/order/getAllDelieveredOrders`
    );
    dispatch({
      type: "GET_ALL_DELIVERED_ORDERS_SUCCESS",
      payload: data.orders,
    });
  } catch (error) {
    if (error.response) {
      error = error.response.data.message;
    } else {
      error = error.message;
    }
    dispatch({ type: "GET_ALL_DELIVERED_ORDERS_FAIL", payload: error });
  }
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: "CLEAR_ERROR" });
};

export const openSearchedOrder = () => async (dispatch) => {
  dispatch({ type: "OTP_ORDER_POP" });
};

export const closeSearchedOrder = () => async (dispatch) => {
  dispatch({ type: "OTP_ORDER_CLOSE" });
};

export const orderResponse = (type, id) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/order/orderResponse/${id}?type=${type}`
    );
    
    console.log(data);
    dispatch({ type: "ORDER_RESPONSE", payload: data.neworders });
  } catch (error) {
    if (error.response) {
      error = error.response.data.message;
    } else {
      error = error.message;
    }
    dispatch({ type: "ORDER_RESPONSE_FAIL", payload: error });
  }
};
