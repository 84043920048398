import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../Api/endpoints";
import "./styles.css";
import  toast,{ Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import SiteLoader from "../SiteLoader/SiteLoader";

const UpdateMenu = ({ setShow, item }) => {
  const navigate = useNavigate();

  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [category, setCategory] = useState("");
  const [price_half, setHalfPrice] = useState(50);
  const [price_full, setFull] = useState(null);
  const [image, setImage] = useState(null);
  const [path, setPath] = useState("");
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (item != null) {
      setname(item.name);
      setCategory(item.category);
      setHalfPrice(item.price_half);
      setFull(item.price_full);
      setdescription(item.description);
    }
  }, [item]);
  const handleClick = async (itemId) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("DualOptions", checked);
    formData.append("category", category);
    if (checked) {
      formData.append("price_full", price_full);
    }

    formData.append("price_half", price_half);
    formData.append("image", image);

    try {
      const res = await axios.post(
        `${BASE_URL}/food/updateFood/` + itemId,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Item Updated Successfully", {
        duration: 1000,
      });
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message, {
         duration: 1000,
        });
      } else {
        toast.error("Internal Server error", {
          duration: 1000,
        });
      }
    }

    setImage(null);
    setdescription("");
    setPath("");
    setname("");
    setCategory("");
    setChecked(!checked);
    setShow(true);
    setShow(false);
  };
  return (
    <div className="Menuu Updatee">
      <h2>Update Item</h2>
      <div className="AddItemm">
        <div className="itemNamee">
          <label>Item Name</label>
          <input
            style={{ fontSize: "14px" }}
            type="text"
            onChange={(e) => {
              setname(e.target.value);
            }}
            value={name}
            name="name"
          />
        </div>
        <div className="categoryy">
          <label>Category</label>
          <input
            style={{ fontSize: "14px" }}
            type="text"
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            value={category}
            name="category"
          />
        </div>
        <div className="itemDescriptionn">
          <label>Description</label>
          <textarea
            name="description"
            style={{ fontSize: "14px" }}
            onChange={(e) => {
              setdescription(e.target.value);
            }}
            value={description}
            id=""
            cols="22"
            rows="1"
          ></textarea>
        </div>
        <div className="DualOptionn">
          <input
            type="checkbox"
            name="DualOptions"
            onClick={() => {
              setChecked(!checked);
            }}
          />
          <label>DualOption</label>
        </div>
        <div className="itemPrice_halff">
          <label>Price(half)</label>
          <input
            type="number"
            style={{ fontSize: "14px" }}
            onChange={(e) => {
              setHalfPrice(e.target.value);
            }}
            value={price_half}
            name="price_half"
          />
        </div>
        <div className="itemPrice_fulll">
          <label>Price(full)</label>
          <input
            type="number"
            style={{ fontSize: "14px" }}
            onChange={(e) => {
              setFull(e.target.value);
            }}
            value={price_full}
            name="price_full"
            disabled={!checked}
          />
        </div>
        <div>
          <label className="imgg">
            + Add Image
            <input
              type="file"
              name="image"
              accept="image/png , image/jpeg, image/webp"
              onChange={(e) => {
                console.log(e.target.files[0]);
                setImage(e.target.files[0]);
                setPath(e.target.value);
              }}
              value={path}
            />
          </label>
          <p style={{ marginBottom: 10 + "px" }}>{path}</p>
        </div>
      </div>
      {loading ? (
        <Button disabled variant="contained" style={{ backgroundColor: "#D80032" }}>
          <SiteLoader variant={"small"} />
        </Button>
      ) : (
        <Button
          type="Submit"
          onClick={() => handleClick(item._id)}
          variant="contained"
          style={{ backgroundColor: "#D80032" }}
        >
          Update
        </Button>
      )}
      <Button
        style={{ margin: 10 + "px " + 0 }}
        type="Submit"
        onClick={() => {
          setShow(false);
        }}
        variant="contained"
      >
        Cancel
      </Button>
      <Toaster />
    </div>
  );
};

export default UpdateMenu;
